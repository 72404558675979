.row {
  margin-bottom: 0;

  .col {
    $i: 1;
    @while $i <= $num-cols {
      $perc: unquote((100 / ($num-cols / $i)) + "%");
      &.xs#{$i} {
        width: $perc;
        margin-left: auto;
        left: auto;
        right: auto;
      }
      $i: $i + 1;
    }

    $i: 1;
    @while $i <= $num-cols {
      $perc: unquote((100 / ($num-cols / $i)) + "%");
      &.offset-xs#{$i} {
        margin-left: $perc;
      }
      &.pull-xs#{$i} {
        right: $perc;
      }
      &.push-xs#{$i} {
        left: $perc;
      }
      $i: $i + 1;
    }

    @media #{$small-and-up} {

      $i: 1;
      @while $i <= $num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");
        &.s#{$i} {
          width: $perc;
          margin-left: auto;
          left: auto;
          right: auto;
        }
        $i: $i + 1
      }

      $i: 1;
      @while $i <= $num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");
        &.offset-s#{$i} {
          margin-left: $perc;
        }
        &.pull-s#{$i} {
          right: $perc;
        }
        &.push-s#{$i} {
          left: $perc;
        }
        $i: $i + 1;
      }
    }

    @media #{$medium-and-up} {

      $i: 1;
      @while $i <= $num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");
        &.m#{$i} {
          width: $perc;
          margin-left: auto;
          left: auto;
          right: auto;
        }
        $i: $i + 1
      }

      $i: 1;
      @while $i <= $num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");
        &.offset-m#{$i} {
          margin-left: $perc;
        }
        &.pull-m#{$i} {
          right: $perc;
        }
        &.push-m#{$i} {
          left: $perc;
        }
        $i: $i + 1;
      }
    }

    @media #{$large-and-up} {
      padding: 0 $gutter-width-large / 2;

      $i: 1;
      @while $i <= $num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");
        &.l#{$i} {
          width: $perc;
          margin-left: auto;
          left: auto;
          right: auto;
        }
        $i: $i + 1;
      }

      $i: 1;
      @while $i <= $num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");
        &.offset-l#{$i} {
          margin-left: $perc;
        }
        &.pull-l#{$i} {
          right: $perc;
        }
        &.push-l#{$i} {
          left: $perc;
        }
        $i: $i + 1;
      }
    }

    @media #{$xl-and-up} {
      padding: 0 $gutter-width-large / 2;

      $i: 1;
      @while $i <= $num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");
        &.xl#{$i} {
          width: $perc;
          margin-left: auto;
          left: auto;
          right: auto;
        }
        $i: $i + 1;
      }

      $i: 1;
      @while $i <= $num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");
        &.offset-xl#{$i} {
          margin-left: $perc;
        }
        &.pull-xl#{$i} {
          right: $perc;
        }
        &.push-xl#{$i} {
          left: $perc;
        }
        $i: $i + 1;
      }
    }
  }
}
.ql-editor {
  strong {
    font-weight: bold;
  }
}

$colors: (
  white, snow, honeydew, mintcream, azure, aliceblue, ghostwhite, whitesmoke, seashell, beige, oldlace, floralwhite, ivory, antiquewhite, linen, lavenderblush, mistyrose,
  indianred, lightcoral, salmon, darksalmon, lightsalmon, crimson, red, firebrick, darkred,
  pink, lightpink, hotpink, deeppink, mediumvioletred, palevioletred,
  lightsalmon, coral, tomato, orangered, darkorange, orange,
  gold, yellow, lightyellow, lemonchiffon, lightgoldenrodyellow, papayawhip, moccasin, peachpuff, palegoldenrod, khaki, darkkhaki,
  lavender, thistle, plum, violet, orchid, fuchsia, magenta, mediumorchid, mediumpurple, rebeccapurple, blueviolet, darkviolet, darkorchid, darkmagenta, purple, indigo, slateblue, darkslateblue, mediumslateblue,
  greenyellow, chartreuse, lawngreen, lime, limegreen, palegreen, lightgreen, mediumspringgreen, springgreen, mediumseagreen, seagreen, forestgreen, green, darkgreen, yellowgreen, olivedrab, olive, darkolivegreen, mediumaquamarine, darkseagreen, lightseagreen, darkcyan, teal,
  aqua, cyan, lightcyan, paleturquoise, aquamarine, turquoise, mediumturquoise, darkturquoise, cadetblue, steelblue, lightsteelblue, powderblue, lightblue, skyblue, lightskyblue, deepskyblue, dodgerblue, cornflowerblue, mediumslateblue, royalblue, blue, mediumblue, darkblue, navy, midnightblue,
  cornsilk, blanchedalmond, bisque, navajowhite, wheat, burlywood, tan, rosybrown, sandybrown, goldenrod, darkgoldenrod, peru, chocolate, saddlebrown, sienna, brown, maroon,
  gainsboro, lightgray, silver, darkgray, gray, dimgray, lightslategray, slategray, darkslategray, black
);

@each $color in $colors {
  .ql-color-#{$color} {
    color: #{$color};
  }
}

@each $label, $font in (sans, sans-serif), (opensans, opensans-light) {
  .ql-font-#{$label} {
    font-family: #{$font};
  }
}

@each $label, $size in (10, 10px), (12, 12px), (14, 14px), (16, 16px), (18, 18px), (24, 24px), (32, 32px) {
  .ql-size-#{$label} {
    font-size: #{$size};
  }
}

.ql-toolbar {

  .ql-font {
    @each $label, $font in ("Sans Serif", sans-serif), ("Open Sans", opensans-light) {
      span[data-label="#{$label}"] {
        &::before {
          font-family: #{$font};
        }
      }
    }
  }

  .ql-size {
    @each $size in (10px, 12px, 14px, 16px, 18px, 24px, 32px) {
      span[data-label="#{$size}"] {
        &::before {
          font-size: #{$size};
        }
      }
    }
  }
}
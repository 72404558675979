.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}

.font-12 {font-size: 12px;}
.font-14 {font-size: 14px;}
.font-17 {font-size: 17px;}
.font-20 {font-size: 20px;}
.font-22 {font-size: 22px;}
.font-25 {font-size: 25px;}
.font-27 {font-size: 27px;}
.font-32 {font-size: 32px;}
.font-xxxl {font-size: 34px;}
.font-xxl {font-size: 26px;}
.font-xl {font-size: 20px;}
.font-lg {font-size: 18px;}
.font-md {font-size: 16px;}
.font-s {font-size: 12px;}
.font-xs {font-size: 11px;}

.line-height-30 {line-height: 30px;}
.line-height-34 {line-height: 34px;}
.line-height-46 {line-height: 46px;}
.line-height-40 {line-height: 40px;}

.font-primary {
  color: $primary-color;
}
.font-black {
  color: black;
}

.font-white {
  color: white;
}

.v-middle {
  display: table-cell;
  vertical-align: middle;
}

.bold {
  font-weight: bold;
}

.m-t-sm {margin-top: 5px !important;}
.m-t-8 {margin-top: 8px !important;}
.m-t-17 {margin-top: 17px !important;}
.m-t {margin-top: 10px !important;}
.m-t-lg {margin-top: 20px !important;}
.m-t-xl {margin-top: 40px !important;}
.m-b-sm {margin-bottom: 5px !important;}
.m-b {margin-bottom: 10px !important;}
.m-b-lg {margin-bottom: 20px !important;}
.m-b-xl {margin-bottom: 40px !important;}

.m-r {margin-right: 10px}

.material-icons.md-14 { font-size: 14px; }
.material-icons.md-16 { font-size: 16px; }
.material-icons.md-18 { font-size: 18px; }
.material-icons.md-24 { font-size: 24px; }
.material-icons.md-36 { font-size: 36px; }
.material-icons.md-48 { font-size: 48px; }


.no-padder {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.no-v-padder {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.padder-6 {
  padding-left: 6px;
  padding-right: 6px;
}

.padder-16 {
  padding-left: 16px;
  padding-right: 16px;
}
.padder-v-md { padding-top: 15px !important;padding-bottom: 15px !important;}
.padder-v-16 { padding-top: 17px !important;padding-bottom: 17px !important;}
.padder-v-18 { padding-top: 18px !important;padding-bottom: 18px !important;}
.padder-v-lg { padding-top: 30px !important;padding-bottom: 30px !important;}
.padder-t-22 {padding-top: 22px !important;}

.p-t-0 { padding-top: 0px !important; }
.p-t-1 { padding-top: 1px !important; }
.p-t-2 { padding-top: 2px !important; }
.p-t-3 { padding-top: 3px !important; }
.p-t-4 { padding-top: 4px !important; }
.p-t-5 { padding-top: 5px !important; }
.p-t-6 { padding-top: 6px !important; }
.p-t-7 { padding-top: 7px !important; }
.p-t-8 { padding-top: 8px !important; }
.p-t-9 { padding-top: 9px !important; }
.p-t-10 { padding-top: 10px !important; }

.b-a-1 {
  border: 1px solid black;
}

.hidden-xxs {
    @media #{$xs-and-down} {
        display: none !important;
    }
}
.hidden-xs {
  @media #{$small-and-down} {
    display: none !important;
  }
}
.hidden-s {
  @media #{$medium-and-down} {
    display: none !important;
  }
}
.hidden-lg {
  @media #{$large-and-down} {
    display: none !important;
  }
}
.hidden-over-lg {
  @media #{$large-and-up} {
    display: none !important;
  }
}

.hidden-over-md {
  @media #{$medium-and-up} {
    display: none !important;
  }
}

.hidden-over-xs {
  @media #{$small-and-up} {
    display: none !important;
  }
}

.pull-right-xs {
  @media #{$xs-and-down} {
    float: right;
  }
}

body{
  overflow-x: hidden;
}

.jobshark-container {
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
}
.jobshark-container-home {

}


.pull-right {
  float: right;
}

.icon-w-15 { width: 15px;}
.icon-w-30 { width: 30px;}

hr {
  .muted-line {
    border-color: lightgrey;
  }
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.nowrap {
  white-space: nowrap;
}
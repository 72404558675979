@font-face {
    font-family: "nexa-light";
    src: url('#{$jobshark-font-path}NEXA-LIGHT.otf');
    font-weight: 200;
}
.nexa-light {
    font-family: "nexa-light", sans-serif;
}
@font-face {
    font-family: "nexa-bold";
    src: url('#{$jobshark-font-path}NEXA-BOLD.otf');
    font-weight: 200;
}
.nexa-bold {
    font-family: "nexa-bold", sans-serif;
}
@font-face {
    font-family: "opensans-light";
    src: url('#{$jobshark-font-path}OPENSANS-LIGHT.ttf');
    font-weight: 200;
}
.opensans-light {
    font-family: "opensans-light", sans-serif;
}
@font-face {
    font-family: "opensans-bold";
    src: url('#{$jobshark-font-path}OPENSANS-BOLD.ttf');
    font-weight: 200;
}
.opensans-bold {
    font-family: "opensans-bold", sans-serif;
}
@font-face {
    font-family: "opensans-regular";
    src: url('#{$jobshark-font-path}OPENSANS-REGULAR.ttf');
    font-weight: 200;
}
.opensans-regular {
    font-family: "opensans-regular", sans-serif;
}
@font-face {
    font-family: "opensans-semibold";
    src: url('#{$jobshark-font-path}OPENSANS-SEMIBOLD.ttf');
    font-weight: 200;
}
.opensans-semibold {
    font-family: "opensans-semibold", sans-serif;
}

@font-face {
    font-family: AwesomeWebFont;
    src: url('#{$jobshark-font-path}fontawesome-webfont.ttf') format('truetype');
}

@font-face {
    font-family: GeorgiaFont;
    src: url('#{$jobshark-font-path}georgia.ttf') format('truetype');
}

@font-face {
    font-family: GeorgiaIFont;
    src: url('#{$jobshark-font-path}georgiai.ttf') format('truetype');
}

@font-face {
    font-family: "Lucida Console";
    src: url('#{$jobshark-font-path}LUCIDA-CONSOLE.ttf') format('truetype');
}

@font-face {
    font-family: "FjallaOne Regular";
    src: url('#{$jobshark-font-path}FjallaOne-Regular.otf');
}

nav {
  box-shadow: none;
  background-color: $white;

  .nav-wrapper {
    padding: 0;

    @media #{$large-and-up} {
      padding: 0 $gutter-width-large;
    }
  }

  @media #{$xs-and-up} {
    a.button-collapse { display: none; }
  }

  .brand-logo {
    padding: 8px 0 0 0;
  }

  ul {
    a {
      &:hover {
        background-color: rgba(255,255,255,0);
        text-decoration: underline;
      }
    }
  }
}
@mixin circle-after($diameter, $circle-color: $primary-color) {
  display: block;
  padding-bottom: $diameter;
  width: $diameter;
  height: 0;
  border-radius: 50%;
  background-color: $circle-color;
  content: "";
}

@mixin circle-inner($diameter) {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: $diameter;
  height: $diameter;
}

@mixin circle-wrapper($diameter) {
  display: table;
  width: $diameter;
  height: $diameter;
}
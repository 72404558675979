.resume_editor {
    background: white;
    margin: auto;
    padding: 60px 60px 40px;

    .details-ico {
        width: 18px;
        margin-right:5px;
        @media #{$xs-and-down} {
            margin-right:3px;
        }
    }

    .user-portrait {
        img {
            width: 100%;
            border-radius: 50%;
        }
    }
    .logo {
        img {
            width: 100%;
        }
    }
    .header {
        margin-bottom: 50px;
    }
    .user-name {
        padding-top: 15px;
        font-family: "nexa-bold";
        font-size: 30pt;
        line-height: 30pt;
        text-transform: uppercase;
        color: #231f20;
    }
    .user-headline {
        margin-top: 10px ;
        font-family: "nexa-bold";
        font-size: 10pt;
        text-transform: uppercase;
        color: #f36c32;
    }
    .user-details {
        margin-top: 20px;
        font-family: GeorgiaFont;
        font-size: 10pt;
        line-height: 18pt;
        color: #231f20;
        @media #{$xs-and-down} {
            min-width: 225px;
        }
    }

    .section-title {
        margin-top: 10px;
        padding-top: 10px;
        font-family: "nexa-bold";
        font-size: 14pt;
        text-transform: uppercase;
        text-align: right;
        color: #231f20;
        border-top: #ff6c2c solid 1px;
        @media #{$xs-and-down} {
            font-size: 9pt;
        }
    }
    .section-content {
        min-height: 103px;
        font-family: GeorgiaFont;
        font-size: 10pt;
        line-height: 18pt;
        text-align: left;
        color: #231f20;
    }

    div [contenteditable="true"]:hover {
        outline: #ff6c2c dashed 1px;
    }
}

#edit-jobshark-cv-modal {
    top: 3% !important;
    max-height: 94% !important;
    width: calc(21cm + 17px) !important;
}


.job-description-hero {
  height: 250px;
  width: 100vw;
  background-image: url('../img/job-description-hero.png');
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 5px;
  padding-top: 45px;
  padding-bottom: 45px;
  margin-left: calc(-1 * ((100vw - 100%) / 2));
  padding-left: calc((100vw - 100%) / 2);
  padding-right: calc((100vw - 100%) / 2);

  @media #{$xs-and-down} {
    padding-top: 10px;
  }

  .job-info {
    font-size: 12px;
    @media #{$xs-and-down} {
      text-align: center;
    }
  }

  .job-title {
    min-height: 104px;
  }

  .fixed-action-btn.horizontal{
    position: relative;
    left: 70px;
    bottom: 26px;
    padding: 0;
    width: 20px;
    @media #{$xs-and-down} {
      left: 0px;
      bottom: -8px;
    }
    @media (min-width: $small-screen-up) and (max-width: $medium-screen)   {
      left: 0px;
      bottom: -5px;
    }
    .btn-floating {
      width: 22px;
      height: 22px;
      line-height: 22px;
      box-shadow: none;
      background-color: #212121;
      i{
        font-size: 1rem;
        line-height: 22px;
      }
    }
    .btn-floating:hover{
      background-color: #545454;
    }
    ul{
      left: 20px;
      width: 125px;
      -webkit-transform: translateY(-123%);
      transform: translateY(-123%);
      @media #{$xxs-and-down} {
        left: 0px;
        width: 120px;
        li{
          margin-right: 9px;
        }
      }
    }
  }
  .fixed-action-btn.horizontal.active{
    width: 120px;
  }

  .btn.apply-button.black{
      background-color: #231f20 !important;
  }
  .btn.apply-button.black:hover{
      background-color: #fd6b2d !important;
  }
}

.job-description {
  padding: 50px 0 25px;

  strong {
    font-weight: bold;
  }

  .job-description-title {
    font-size: 12px;
    color: #999999;
  }

  .job-description-logo {
    margin-bottom: 85px;

    .card-logo {
      display: block;
      width: 100%;
      height: auto;
      margin-top: 7px;

      @media #{$small-and-up} {
        margin-top: 0;
      }
      @media #{$medium-and-up} {
        margin-top: 7px;
      }
      @media #{$large-and-up} {
        margin-top: 0;
      }
    }
  }
  .job-detail {
    margin-bottom: 35px;
    .apply-button{
      margin: auto -25%;
    }
    .apply-button.disabled {
      padding: 10px 0px 46px;
      min-width: 180px;
      margin: auto -25%;
      @media #{$medium-and-down} {
        min-width: 160px;
      }
    }

  }
  ul {
    padding-left: 3.5em;
    list-style: disc outside none;
    li {
      list-style-type: disc;
    }
  }

  ol {
    padding-left: 3.5em;
  }
}

.full-width-xs {
  @media #{$xs-and-down} {
    width: 100%;
  }
}

.apply-button {
  padding: 10px 60px 46px;
  @media #{$xs-and-down} {
    padding: 10px 40px 46px;
  }
}
.apply-button.disabled {
  @media #{$medium-and-down} {
    padding: 10px 0px 46px;
    min-width: 200px;
  }
  @media #{$small-and-down} {
    padding: 10px 0px 46px;
    min-width: 160px;
  }
  @media #{$xxs-and-down} {
    min-width: 125px;
    font-size: 12px;
  }
}

.bottom-actions-bar {
  background-color: #ff6c2c;
  width: 100vw;
  margin-left: calc(-1 * ((100vw - 100%) / 2));
  padding: 0 calc((100vw - 100%) / 2);

  .fixed-action-btn.horizontal{
    position: relative;
    left: 70px;
    bottom: 26px;
    padding: 0;
    width: 20px;
    @media #{$xs-and-down} {
      left: 0px;
      bottom: -2px;
    }
    @media (min-width: $small-screen-up) and (max-width: $medium-screen)   {
      left: 0px;
      bottom: -2px;
    }
    .btn-floating {
      width: 22px;
      height: 22px;
      line-height: 22px;
      box-shadow: none;
      background-color: #212121;
      i{
        font-size: 1rem;
        line-height: 22px;
      }
    }
    .btn-floating:hover{
      background-color: #545454;
    }
    ul{
      left: 20px;
      width: 125px;
      -webkit-transform: translateY(-108%);
      transform: translateY(-108%);
      @media #{$xxs-and-down} {
        left: 0px;
        width: 120px;
        -webkit-transform: translateY(-116%);
        transform: translateY(-116%);
        li{
          margin-right: 9px;
        }
      }

    }
  }
  .fixed-action-btn.horizontal.active{
    width: 120px;
  }
  .btn.apply-button{
    background: transparent;
    border: 1px solid #231f20;
    color: #231f20;
  }
  .btn.apply-button:hover{
    background-color: #231f20;
    color: #fff;
  }
}


.company-profile-hero {
  .top-half {
    height: 140px;
    width: 100vw;
    background-image: url('../img/user-profile-hero.png');
    background-repeat: no-repeat;
    background-size: cover;
    margin-left: calc(-1 * ((100vw - 100%) / 2));
    padding-left: calc((100vw - 100%) / 2);
    padding-right: calc((100vw - 100%) / 2);
    @media #{$xs-and-up} {
      height: 250px;
    }
    img{
      width: 170px;
      position: absolute;
      right: 10px;
      top: 220px;
      @media #{$xl-and-up} {
        right: calc((100% - 1400px)/2);
      }
      @media #{$small-and-down} {
        display: none;
      }
    }
  }
  .bottom-half {
    height: 300px;

    @media #{$small-and-up} {
      height: 170px;
    }

    .company-portrait {
      float: none;
      margin-left: auto;
      margin-right: auto;
      margin-top: -105px;
      text-align: center;
      /*float: none;
      margin-left: auto;
      margin-right: auto;
      border-radius: 50%;
      border: 1px solid lightgray;
      width: 210px;
      height: 210px;
      margin-top: -105px;
      background: white;
      padding-top: 65px;*/

      img {
        background: white;
        border: 1px solid lightgray;
        width: 210px;
        height: 210px;
        border-radius: 50%;
      }


      @media #{$small-and-up} {
        margin-left: $gutter-width-large;
        margin-right: 0;
        float: left;
      }
    }

    .company-details-wrapper {
      margin-top: 10px;
      float: left;
    }

    .user-details {
      padding: 0 $gutter-width;

      @media #{$large-and-up} {
        padding: 0 $gutter-width-large;
      }
    }
  }
}

.job-listings {
  padding: 0 $gutter-width/2 25px;

  .company-profile-btn {
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    i {
      margin-right: 5px;
    }
  }

  .button-menu {
    @media #{$medium-and-down} {
      margin-left: -10px !important;
    }

    .switch {
      padding-top: 5px;

      label {
        .lever {
          background:none;
          width: 25px;
          left: -4px;
          border: 2px solid #818181;
          margin-right: 4px;

          &:after {
            width: 13px;
            height: 13px;
            top: -1px;
            left: -1px;
            border: 2px solid #818181;
            box-shadow: none;
          }
        }

        input[type="checkbox"]:checked + .lever {
          background-color: #4caf50;
          border: 2px solid #4caf50;
        }

        input[type="checkbox"]:checked + .lever:after {
          background-color: #fff;
          border: 2px solid #4caf50;
          left: 9px;
        }
      }
    }
  }

  .inactive {
    .status {
      color: grey;
    }
    .normal-text {
      color: grey;
    }
    .job-title-text {
      color: grey;
    }
  }

  .active {
    .status {
      color: #4CAF50;
    }
    .job-title-text {
      color: #fd6d31;
    }
  }

  div.actions {
    @media #{$xl-and-up} {
      text-align: right;
    }

    a, span {
      display: inline-block;
      padding-left: 15px;
      padding-top: 8px;
    }
  }

}

a {
  color: inherit;
}

#notification-emails {
  padding: 10px 0;
  margin: 0 0 0 25px;
}

div {
  &.notification-email {
    margin: 0 0 0 25px;
    padding: 0;

    input {
      margin: 0;
      border: 0;
      border-bottom: 2px solid $primary-color;
      padding: 0;
      display: inline-block;
      vertical-align: middle;
      white-space: normal;
      background: none;
      line-height: 1;
      font-family: "opensans-light", sans-serif;
      font-size: 18px;

      -webkit-box-sizing: content-box;
      -moz-box-sizing: content-box;
      box-sizing: content-box;

      &:focus {
        outline: 0;
      }
    }

    a {
      margin: 0 8px;
      font-family: "nexa-bold", sans-serif;
      font-size: 15px;
    }

    span {
      font-size: 18px;
      display: inline-block;
      width: 100%;
    }
  }
}

#add-new-notification-email {
  margin:0 0 0 25px;
  padding: 0;

  span {
    display: block;
    font-family:"opensans-light", sans-serif;
    font-style: italic;
    font-size: 15px;
    color: #a1a4aa;
    text-indent: 14px;
  }

  a {
    display: block;
    font-family: "nexa-bold", sans-serif;
    font-size: 14px;
    margin: 0;

    &:hover {
      color: #ff6c2c;
    }
  }
}

#add-widget-container {
  h4 {
    color: $primary-color;
    font-family: "Nexa Light", sans-serif;
    font-size: 32px;
    text-align: center;
    margin-bottom: 80px;
  }

  h6 {
    color: $primary-color;
    font-family: "Nexa Light", sans-serif;
    font-size: 26px;
  }

  p {
    font-family: "opensans-light", sans-serif;
    font-size: 18px;
  }

  textarea {
    width: 90%;
    height: 150px;
    background-color: #e2e2e2;
    font-family: "Lucida Console", sans-serif;
    font-size: 16px;
    padding: 5px;
    border:1px solid #d8d8d8;
  }

  button {
    display: inline-block;
    cursor: pointer;
    background: url("../img/copy-widget-button.png") no-repeat;
    background-size: 264px 122px;
    width: 266px;
    height: 124px;
    padding: 0;
    margin: 0;
    border: 1px solid transparent;

    &:hover {
      border: 1px solid #d8d8d8;
    }
  }
}

.push-company-profile-footer {
    form{
      .input-field{
        margin-top: 2.5rem;
        label{
          top: 0rem;
        }
        textarea{
          padding-bottom: 10px;
          min-height: 0px;
        }
        .select-wrapper{
          width: 100.8%;
          input.select-dropdown{
            padding-left: 10px;
          }
        }
        .select-wrapper + label {
          top: -25px;
        }
      }

    }
}

#embeddable-widget-code {
  resize: none;
}

#company-jobs-container {
  margin-top: 0;
}

.company-description {
  padding-left: 10px;

  strong {
    font-weight: bold;
  }

  ul {
    padding-left: 3.5em;
    list-style: disc outside none;
    li {
      list-style-type: disc;
    }
  }

  ol {
    padding-left: 3.5em;
  }
}
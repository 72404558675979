.widget-apply-panel {
  background: #f5f5f6 url('../img/faded-shark.png') no-repeat bottom 10px right 10px;
  background-size: 325px 140px;
  padding: 10px;
  min-height: 160px;
  margin-top: 10%;

  .half-border {
    border-top: 5px solid $primary-color;
  }
}

#widget-apply-form {

  font-family: "Nexa Light", sans-serif;

  label {
    padding-top: 2px;
    color: #333;

    &.disabled {
      text-decoration: line-through;
      color: #999;
    }
  }

  p {
    margin: 0;
    padding: 0;
  }

  fieldset {
    border-top: 2px solid $primary-color;
    border-right: none;
    border-left: none;
    border-bottom: none;

    legend {
      padding: 0 4px 0 4px;
      font-size: 18px;
      color: $primary-color;
    }
  }

  .alternative {
    background-color: $primary-color;
    height: 50px;
    width: 50px;
    line-height: 50px;
    border-radius: 50%;
    text-align: center;
    color: $white;
    padding-top: 2px;
    margin-left: 50px;
  }

  .row {
    a {
      text-decoration: none;
      border: 1px solid $primary-color;
      background-color: $primary-color;
      color: $white;
      padding: 7px 8px 5px 8px;
      margin-right: 5px;
    }
  }

}

#widget-logo {
  cursor: pointer;
  background: url('../img/jobshark-logo-small.png') no-repeat top 50% left 10px;
  background-size: 176px 44px;
  width: 186px;
  height: 88px;
  padding: 0;
  margin: 0;
}

#widget-fill-apply-options {
  clear: both;
  position: relative;

  div.alternative {
    text-align: center;
    position: absolute;
    width: 110px;
    top: 52px;
    left: 46%;

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      border-top: 2px solid $primary-color;
      background: $primary-color;
      width: 100%;
      transform: translateY(-50%);
      z-index: -1;
    }

    span {
      text-transform: uppercase;
      text-align: center;
      margin: 0 auto;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 22px;
      color: white;
      background-color: $primary-color;
      display: block;
      width: 60px;
      height: 60px;
      line-height: 60px;
      border-radius: 50%;
      padding-top: 2px;
    }
  }

  h4 {
    color: #faa445;
    text-align: center;
    margin-bottom: 60px;
    margin-top: 60px;
    font-family: "FjallaOne Regular", sans-serif;
    font-size: 40px;
  }

  div.col.col-first {
    padding-right: 30px;
    border-right: 3px solid $primary-color;
    min-height: 300px;

    @media #{$medium-and-down} {
      border-right: none !important;
    }

    @media #{$xs-and-down} {
      padding-right: 10px;
    }

    span {
      padding-right: 2px;
      cursor: pointer;
    }
  }

  div.col.col-last {
    padding-left: 60px;

    @media #{$xs-and-down} {
      padding-left: 0px;
    }
    span, label span {
      display: block;
      line-height: 24px;
      font-family: "Nexa Light", sans-serif;
      font-weight: 500;
      font-size: 24px;
      cursor: pointer;
      margin-top: 20px;
      color: black;

      @media #{$medium-and-down} {
        text-align: center;
      }

      i {
        margin-right: 8px;
      }
    }
  }
}

#widget-add-linkedin-profile-input {
  width: 80%;
  margin: 20px 8px 0 8px;
  display: inline;
  color: black;
}

#widget-edit-jobshark-cv {
  border: 1px solid $primary-color;
  padding: 20px;
  width: 100%;
  background: url('../img/jobshark-logo-big.png') no-repeat top 20px right 20px;
  background-size: 20%;

  @media #{$xs-and-down} {
    padding: 20px 0;
  }
  .user-portrait {
    img {
      cursor: pointer;
    }
  }

  .user-name {
    padding-top: 0;
  }

  .user-headline {
    line-height: 15pt;
  }

  .editable {
    border: 1px solid transparent;

    &:hover {
      border: 1px dotted $primary-color;
      cursor: default;
    }
  }

  div.user {
    div.user-details {
      div {
        padding: 0;

        span {
          cursor: default;
          display: inline-block;
          line-height: 10pt;
          padding: 2px;
          width: 90%;
          vertical-align: middle;
        }
      }
    }
  }

  div.section-content {
    textarea, div {
      line-height: 18pt;
      margin-bottom: 2px;
    }
  }

  div.summary, div.skills {
    div.editable {
      min-height: 103px;
      height: 100%;
    }
  }

  div.actions {
    div.col {
      span.red-text {
        display: block;
      }
    }
  }

  textarea {
    border: 1px solid $primary-color;
    font-family: GeorgiaFont;
    font-size: 10pt;
    box-shadow: none;
    border-radius: 0;
    resize: none;
    overflow: auto;
    line-height: 10pt;
    display: inline-block;
    vertical-align: middle;
    padding: 2px;
    outline: none;

    &.no-overflow {
      overflow: hidden;
    }

    &.user-name {
      font-family: "nexa-bold";
      font-size: 30pt;
      line-height: 30pt;
      text-transform: uppercase;
      margin-top: 1px;
    }

    &.user-headline {
      font-family: "nexa-bold";
      text-transform: uppercase;
      margin-top: 11px;
      line-height: 15pt;
    }
  }
}

#widget-job-details {
  .bottom-actions-bar {
    background-color: transparent;
    .apply-button {
      background-color: $primary-color;
      border: none;
      font-family: "nexa-light", sans-serif;
      color: $white;

      &:hover {
        background-color: black;
      }
    }
  }
}

#widget-job-list {
  .card {
    .card-action {
      a.apply-button {
        border: 1px solid transparent;
        display: block;
        background-color: black;
        font-family: "nexa-light", sans-serif;
        font-size: 18px;
        color: $white;
        padding: 4px 60px 0;
        margin: 8px 0 0 0;
        text-align: center;

        &:hover {
          border: 1px solid black;
          background-color: transparent;
          color: black;
        }
      }

      .widget-job-share {
        margin-left: 2px;

        a {
          cursor: pointer;
          display:inline-block;
          font-size: 16px;
          color: black;
        }

        ul {
          display: inline-block;
          margin: 0;
          padding: 0;
          font-size: 16px;

          li {
            display: inline;

            a {
              margin-right: 7px;
            }
          }
        }
      }
    }
  }
}

@media #{$small-and-down} {
  .side-nav {
    &.fixed {
      transform: translateX(-105%);

      &.right-aligned {
        transform: translateX(105%);
      }
    }

    a {
      padding: 0 $sidenav-padding;
    }

    .userView {
      padding: $sidenav-padding $sidenav-padding 0;
    }
  }
}
.card {
  box-shadow: 2px 2px 5px -2px rgba(0, 0, 0, 0.16), 0 1px 2px 0 rgba(0, 0, 0, 0.12);
  min-height: 222px;
  @media #{$medium-and-down} {
    min-height: 210px;
  }
  @media #{$small-and-down} {
    min-height: unset;
  }
  .card-logo {
    @media #{$large-and-up} {
      margin-bottom: 10px;
    }
  }

  &.small {
    height: 130px;
  }

  .card-action {
    padding: 0 $card-padding 8px;
    border-top: none;

    .card-hr {
      border-color: $card-hr-color;
    }

    a:not(.btn):not(.btn-large):not(.btn-floating) {
      &.right {
        margin-right: 0;
        margin-top: 2px;
      }

      &.jobshark-orange-text {
        color: #fd6b2d;
      }
    }

    span.link-group {
      margin-right: $card-padding;
      display: inline-block;
      a:not(.btn):not(.btn-large):not(.btn-floating) {
        margin: 0;
      }
      .fixed-action-btn.horizontal{
        position: absolute;
        left: 80px;
        bottom: 12px;
        padding: 0;
        width: 20px;
        .btn-floating {
          width: 16px;
          height: 16px;
          line-height: 16px;
          box-shadow: none;
          background-color: #212121;
          i{
            font-size: 1rem;
            line-height: 16px;
          }
        }
        .btn-floating:hover{
          background-color: #545454;
        }
        ul{
          left: 0;
          width: 125px;
          -webkit-transform: translateY(-104%);
          transform: translateY(-104%);
          @media #{$small-and-down}{
            width: 110px;
            -webkit-transform: translateY(-110%);
            transform: translateY(-110%);
          }
          li{
            @media #{$small-and-down}{
              margin-right: 12px;
            }
          }
        }
      }
      .fixed-action-btn.horizontal.active{
        width: 120px;
        @media #{$small-and-down}{
          width: 110px;
        }
      }
    }
  }
}
.hero {
  background-image: url("../img/hero.png");
  height: 525px;
  width: 100vw;
  padding: 115px calc((100vw - 100% + 16px) / 2) 0;
  margin-top: 5px;
  background-repeat: no-repeat;
  background-size: cover;
  margin-left: calc(-1 * ((100vw - 100%) / 2));


  @media #{$large-and-up} {
    padding: 115px calc((100vw - 100% + 40px) / 2) 0;

  }
  .hero-search-form {
    @media #{$large-and-up} {
      width: 66.66%;
      margin-left: auto;
      margin-right: auto;
    }
    //@media #{$xl-and-up} {
    //  width: 935px;
    //}
  }

  #search-btn {
    height: 60px;
    line-height: 62px;
    box-shadow: none;
    &:hover {
      background-color: #231f20;
    }
  }

  .hero-text {
    margin: 0 0 20px 0;
    background-image: radial-gradient(ellipse 285px 55px , white 10%, rgba(255,255,255,0) 100%);
    border-radius: 50%;
    padding: 20px 30px;

  }

  .job-search-form {
    box-sizing: border-box;
    padding: 5px 10px;
    height: 4rem;
    margin-bottom: 8px;
    border: 1px $primary-color solid;
  }
  #search-query{
    padding-left: 50px;
    background: url(../img/search.png) no-repeat scroll 7px 10px;
    background-color:#fff;
  }
  #search-location{
    padding-left: 45px;
    background: url(../img/pin.png) no-repeat scroll 7px 10px;
    background-color:#fff;
  }
}

.sticky-search-button {
  position: fixed;
  bottom: 10px;
  right: $gutter-width;
  box-shadow: none;

  @media #{$large-and-up} {
    right: 0;
    top: 590px;
  }

  .square-search-variant {
    padding-left: 10px;
    padding-right: 10px;
    box-shadow: none;
  }
}

.instructions {
  .instructions-header {
    padding: 30px 0 40px;
    $hero-circle-width: 100%;


    @media #{$large-and-up} {
      padding: 0 0 0 20px;
    }

    .instructions-circle {
      width: $hero-circle-width;
      max-width: 230px;
      margin-left: auto;
      margin-right: auto;
    }

    .circle:after {
      @include circle-after($hero-circle-width);
    }

    .circle-inner {
      @include circle-inner($hero-circle-width);
    }

    .circle-wrapper {
      @include circle-wrapper($hero-circle-width);
    }

    .circle-content {
      display: table-cell;
      padding: 40px;
      line-height: 2;
      vertical-align: middle;
    }
  }
  .instructions-body {
    padding: 0 16px;
  }

  @media #{$large-and-up} {
    padding-top: 50px;
  }
}

.simple-card {
  border-top: 5px solid $primary-color;
  height: 280px;

  .simple-card-icon {
    height: 130px;
    padding-top: 30px;
    text-align: center;
  }
}

.fancy-card {
  .upper-half {
    padding: 25px 0 5px;
    border-bottom: 5px solid $primary-color;
  }
  .lower-half {
    padding: 13px 0;
  }
}

.job-list {
  margin-top: -60px;

  .card-logo {
    display: block;
    width: 80px;
    height: 80px;
    margin-top: 7px;
    @media #{$small-and-down} {
      width: 65px;
      height: 65px;
      margin-top: 0px;
    }
    @media #{$small-and-up} {
      margin-left: auto;
      margin-right: auto;
      margin-top: 0;
    }
    @media #{$medium-and-up} {
      margin-top: 7px;
    }
    @media #{$large-and-up} {
      margin-left: auto;
      margin-right: auto;
      margin-top: 0;
    }
  }


  .job-list-header {
    padding: 13px 0;
  }
  .job-list-body {
    background: #f0f0f1;
    padding: 20px calc((100vw - 100% + 16px) / 2) 20px;
    width: 100vw;
    margin-left: calc(-1 * ((100vw - 100%) / 2));
  }
  .job-list-footer {
    padding: 25px 0;
    text-decoration: underline;
  }
}

.description {
  width: 100vw;
  margin-left: calc(-1 * ((100vw - 100%) / 2));
  padding: 80px calc((100vw - 100% + 32px) / 2) 100px;
  background-color: #f5f5f6;

  .half-border {
    border-top: 5px solid $primary-color;
  }
  .stats-body {
    line-height: 25px;
    padding-left:0px;
  }

  .step {
    @media #{$medium-and-up} {
      margin-bottom: 8px;
    }
    @media #{$large-and-up} {
      margin-bottom: 8px;
      text-align: center;
    }
  }

  .steps {
    background-image: url('../img/faded-shark.png');
    background-position: right;
    background-size:  contain;
    background-repeat: no-repeat;
    height: 200px;
    margin-top: -70px;
    padding-top: 70px;
    @media #{$medium-and-down} {
      margin-top: inherit;
      padding-top: inherit;
    }
  }

  .step-content {
    padding-top: 16px;
  }
}

.mobile-hero {
  position: relative;
  height: 585px;
  color: $white;
  background-image: url('../img/mobile-hero.png');
  background-repeat: no-repeat;
  padding: 70px calc((100vw - 100% + 47px) / 2);
  width: 100vw;
  margin-left: calc(-1 * ((100vw - 100%) / 2));
  background-size: cover;

  .mobile-hero-text {
    color: #f3eeeb;
    font-size: 18px;
    line-height: 22px;
    text-align: right;
    padding: 20px calc((100vw - 1400px) / 2) 20px 20px ;
    position: absolute;
    right: 3%;
    bottom: 8%;
    background-image: radial-gradient(ellipse 125px 47px , #7b7b7b 10%, rgba(255,255,255,0) 100%);

    @media #{$xl-and-up}{
      background-position-x: calc(-1*(100vw - 1460px) / 4);
    }
    .link {
      color: orange;
    }
  }

  .hand-phone-wrapper {
    position: absolute;
    margin-top: 50px;

    @media #{$small-and-up} {
      margin-top: -10px;

    }
    @media #{$medium-and-up} {
      margin-left: 5%;
      margin-top: -65px;
    }

    .hand-phone {
      height: 465px;
      @media #{$small-and-up} {
        height: 525px;
      }
      @media #{$medium-and-up} {
        height: 580px;
      }
    }
  }

  .mobile-hero-circle {
    width: $hero-circle-sm-width;
    float: right;
    @media #{$medium-and-up} {
      margin-right: 15%;
    }
  }

  .circle:after {
    @include circle-after($hero-circle-sm-width, #f8a345);
  }

  .circle-inner {
    @include circle-inner($hero-circle-sm-width);
  }

  .circle-wrapper {
    @include circle-wrapper($hero-circle-sm-width);
  }

  .circle-content {
    display: table-cell;
    padding: 41px;
    vertical-align: middle;
  }

  h5 {
    line-height: 1.4;
  }
}

.footer-hero {
  background-image: url("../img/bottom-hero.png");
  background-repeat: no-repeat;
  height: 525px;
  width: 100%;
  padding: 175px $gutter-width 0;

  @media #{$large-and-up} {
    padding: 175px $gutter-width-large 0;
  }

  .hero-text {
    margin: 0 0 60px 0;
    padding: 0 30px;
  }
}

.prefooter {
  height: 585px;
  color: $white;
  background-image: url('../img/prefooter.png');
  background-repeat: no-repeat;
  padding: 70px 16px;

  .prefooter-circle {
    width: $hero-circle-width;
    margin-left: auto;
    margin-right: auto;

      @media #{$small-and-down} {
          position: absolute;
          left: -20%;
          right: -20%;
      }
  }


  .circle:after {
    @include circle-after($hero-circle-width);
  }

  .circle-inner {
    @include circle-inner($hero-circle-width);
  }

  .circle-wrapper {
    @include circle-wrapper($hero-circle-width);
  }

  .circle-content {
    display: table-cell;
    padding: 75px;
    line-height: 2;
    vertical-align: middle;
  }
}

.circle {
  position: relative;
  display: block;
  background-color: rgba(255,255,255,0);
}

.large-icon {
    height: 45px;
    width: 45px;
}

.xl-icon {
  height: 56px;
  width: 56px;
}



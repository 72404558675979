.btn-block {
  width:100%;
}
.btn-primary {

}
.btn,.btn:hover{
  box-shadow: none;
}
.btn.black:hover{
  background-color: #fd6b2d !important;
}


.jobshark-gradient {
  background: linear-gradient(90deg, #fbc484, $primary-color);
  width: 100vw;
  height: 5px;
  margin-left: calc(-1 * ((100vw - 100%) / 2));
}

.button-collapse {
    //position: absolute;
    //right: 10px;
}

.nav-wrapper {
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 $gutter-width !important;

    .brand-logo {
        position: relative;
        left: 0;
        display: inline-block;
        transform: none;

        //@media #{$medium-and-up} {
        //    left: 0;
        //}
    }
}

.login-button {
  color: $primary-color;
  padding-right: $gutter-width;
  padding-left: $gutter-width;
  font-size: 14px;


  .login-icon {
      vertical-align: bottom;
      color: black;
      text-align: center;
  }
}

.regular-input {
    padding-left: 8px;
    border: 1px solid $primary-color !important;
}

$placeholder-color: #5b5b5b;
::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color:    $placeholder-color;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color:    $placeholder-color;
    opacity:  1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
    color:    $placeholder-color;
    opacity:  1;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color:    $placeholder-color;
}

.footer {
  padding: 15px 8px 0;

  .auth-buttons {
    padding: 35px 0;


    @media #{$large-and-up} {
      padding: 0;
    }
  }
  .footer-padder {
    padding: 24px;
    background-image: url('../img/footer-shark.png');
    background-repeat: no-repeat;
    background-position: right bottom;

    @media #{$large-and-up} {
      padding: 35px 0 25px;
    }
  }
}

.copyright {
  padding: 15px 0;
  font-size: 12px;
}

.svg-icon {
    height: 30px;
    width: 30px;
}

.modal.modal-narrow {
    width: 100%;

    @media #{$xs-and-up} {
        width: 85%;
    }
    @media #{$small-and-up} {
        width: 68%;
    }
    @media #{$medium-and-up} {
        width: 50%;
    }
    @media #{$large-and-up} {
        width: 45%;
    }
    @media #{$xl-and-up} {
        width: 31%;
    }
}
.third-party-btn {
    width: 100%;
    margin-top: 7px;
}
.modal {
    @media #{$xs-and-down} {
        h4 {
            font-size: 7.5vw;
        }
    }
}

.push-admin-home-footer {
    min-height: calc(100vh - 318px);
}
.push-employer-create-footer {
    min-height: calc(100vh - 282px);
}
.push-company-profile-footer {
    min-height: calc(100vh - 345px);
}
.push-applicants-footer {
    min-height: calc(100vh - 275px);
}
.push-accounts-footer {
    min-height: calc(100vh - 275px);
}
.push-employer-accounts-footer {
  min-height: calc(100vh - 275px);
}
.push-error-footer {
  min-height: calc(100vh - 295px);
}

.inline-block {
    display: inline-block;
}

.bug-report {
    min-height: 300px;
}

.nowrap {
    white-space: nowrap;
}

.w-315 {
    width: 315px;
}


.close-modal-btn {
  font-size: 18px;
  color: lightgray;
  margin-right: -10px;
  cursor: pointer;
}

.modal {
  > .modal-content {
    padding: 36px;

    h5 {
      font-family: "nexa-light", sans-serif;
      color: $primary-color;
      text-align: center;
      font-weight: 700;
    }

    p {
      font-family: "opensans-light", sans-serif;

      span.bold {
        font-family: "opensans-bold", sans-serif;
      }
    }
  }
}

#login-modal {
  > .modal-content {
    #jobseeker-email-input, #jobseeker-password-input {
      width: calc(100% - 24px);
      margin-bottom: 7px;
    }

    #jobseeker-login-modal-submit-btn {
      text-transform: none;
    }
  }
}

#jobseeker-change-account-password-modal {
  > .modal-content {
    input[type="password"] {
      width: calc(100% - 24px);
      margin-bottom: 7px;
    }

    #change-password-modal-submit-btn {
      text-transform: none;
    }
  }
}

#account-apply-choices-modal {
  > .modal-content {
    legend, label, a, p {
      font-family: "nexa-light", sans-serif;
    }

    a {
      font-size: 14px;
      padding-top: 2px;
    }
  }
}

#bug-report-modal, #view-bug-report-modal {
  > .modal-content {
    textarea {
      resize: none;
      border: 1px solid $primary-color;
    }
  }
}

tr {
  &.tablesorter-headerRow {
    th {
      cursor: pointer;

      &:focus {
        outline: none;
      }

      &.tablesorter-headerAsc {
        div {
          &.tablesorter-header-inner:after {
            font-family: FontAwesome;
            content: "\f0de";
            display: block;
            float: right;
            padding-right: 10px;
          }
        }
      }

      &.tablesorter-headerDesc {
        div {
          &.tablesorter-header-inner:after {
            font-family: FontAwesome;
            content: "\f0dd";
            display: block;
            float: right;
            padding-right: 10px;
          }
        }
      }

      &.tablesorter-headerUnSorted {
        div {
          &.tablesorter-header-inner:after {
            font-family: FontAwesome;
            content: "\f0dc";
            display: block;
            float: right;
            padding-right: 10px;
          }
        }
      }

      &.sorter-false {
        div {
          &.tablesorter-header-inner:after {
            content: "";
          }
        }
      }
    }
  }
}

#slide-out.side-nav {
    li {
        border-bottom: 1px solid lightgray;
    }
}

.jobshark-orange-text {
    color: $primary-color;
}

.cursor-pointer {
  cursor: pointer;
}






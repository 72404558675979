.user-profile-hero {
    margin-top: 5px;
  .top-half {
    height: 140px;
    width: 100vw;
    background-image: url('../img/user-profile-hero.png');
    background-repeat: no-repeat;
    background-size: cover;
    margin-left: calc(-1 * ((100vw - 100%) / 2));
    padding-left: calc((100vw - 100%) / 2);
    padding-right: calc((100vw - 100%) / 2);

    img{
      width: 170px;
      position: absolute;
      right: 10px;
      top: 220px;
      @media #{$xl-and-up} {
        right: calc((100% - 1400px)/2);
      }
      @media #{$small-and-down} {
        display: none;
      }
    }
    @media #{$small-and-up} {
      height: 250px;
    }
  }
  .bottom-half {
    height: 315px;

    @media #{$small-and-up} {
        height: 225px;
    }

    .user-portrait {
      float: none;
      margin-left: auto;
      margin-right: auto;
      margin-top: -105px;


      img {
          background: white;
          border-radius: 50%;
          border: 1px solid lightgray;
          width: 210px;
          height: 210px;
      }

      @media #{$small-and-up} {
        padding-left: $gutter-width-large;
        margin-right: 0;
        float: left;
      }
      @media #{$small-and-down} {
        text-align: center;
      }
    }
      @media #{$xs-and-down} {
          a.btn {
              padding-left: 10px;
              padding-right: 10px;
          }
      }


    .user-details-wrapper {
      margin-top: 10px;
      float: left;
    }

    .user-details {
      padding: 0 $gutter-width;


        @media #{$small-and-up} {
            padding-left: 110px !important;

        }

        @media #{$medium-and-up} {
            padding-left: 45px !important;

        }

      @media #{$large-and-up} {
        padding: 0 $gutter-width-large;
      }
    }
  }
}

.user-profile-tab-selector {
  //height: 56px;
  padding: 0 $gutter-width;

  @media #{$small-and-down} {
    margin-top: 140px;
  }

  .user-profile-tab-button {
    padding: 16px 0 15px;
    font-size: 18px;
    color: #fff;
    i {
      margin-top: 3px;
    }
  }
  .user-profile-tab-button:hover,.user-profile-tab-button:focus{
    background-color: #fd6b2d;
  }
  .tab-highlighted {}
}
.user-profile-tab-selector.fixed-menu{
  position: fixed;
  top: -140px;
  width: 100%;
  z-index: 999;
  @media #{$small-and-up} {
    top: 0px;
  }
}

.user-profile-tab {
  padding: 0 $gutter-width 25px;

  @media #{$medium-and-down} {
    //display: none;
  }
  .tab-title {
    padding: 16px 0 15px;
    text-align: center;
    @media #{$small-and-up} {
      text-align: left;
    }
  }

  .inactive {
    .status {
      color: grey;
    }
    .normal-text {
      color: grey;
    }
  }

  .active {
    .status {
      color: #4CAF50;
    }
  }

  .user-profile-btn {
    padding-left: 16px;
    padding-right: 16px;

    @media #{$xs-and-down} {
      font-size: 10px;
      padding-left: 5px;
      padding-right: 0px;
    }
    i {
      margin-right: 5px;
    }
  }

  .card-logo {
    display: block;
    width: 80px;
    height: 80px;
    margin-top: 7px;

    @media #{$small-and-up} {
      margin-left: auto;
      margin-right: auto;
      margin-top: 0;
    }
    @media #{$medium-and-up} {
      margin-top: 7px;
    }
    @media #{$large-and-up} {
      margin-left: auto;
      margin-right: auto;
      margin-top: 0;
    }
  }
  form {
    .input-field{
      padding-left: 16px;
    }
  }
}

.middle-tab {
  @media #{$medium-and-up} {
    background: #f0f0f1;
  }
}

.active-tab {
  display: inherit;
}

.editable-field {
    input {
        border: $primary-color dashed 1px !important;

        &:focus:not([readonly]) {
            border-bottom: $primary-color dashed 1px !important;
            box-shadow: none;
        }
    }
}

#account-name-input {
  font-size: 32px;
  height: 46px;
  line-height: 46px;
  color: $primary-color;
  margin: 0;
}

#account-phone-number-input, #account-location-input, #social-profile-url-input {
  font-size: 20px;
  height: 38px;
  line-height: 38px;
  margin: 0;
  display: inline-block;
}
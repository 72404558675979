// overrides some of the variables from materialize
// as well as adding some new ones

$primary-color: #fd6b2d;
$secondary-color: #fd6b2d;
$white: white;

$button-height: 36px;
$button-large-height: $button-height * 1.3;

$card-padding: 16px;
$card-link-color: #212121;

$fa-font-path: "../fonts";
$roboto-font-path: "../fonts/";
$jobshark-font-path: "../fonts/";

$input-padding: 10px;
$label-font-size: 1rem;

$xs-screen-up: 481px;
$small-screen-up: 601px;
$medium-screen-up: 841px;
$large-screen-up: 961px;
$xl-screen-up: 1401px;
$xs-screen: 480px;
$medium-screen: 840px;
$large-screen: 960px;
$xl-screen: 1400px;
$xxs-screen: 350px;

$xs-and-up: "only screen and (min-width : #{$xs-screen-up})";
$small-and-up: "only screen and (min-width : #{$small-screen-up})";
$medium-and-up: "only screen and (min-width : #{$medium-screen})";
$large-and-up: "only screen and (min-width : #{$large-screen})";
$xl-and-up: "only screen and (min-width : #{$xl-screen})";
$xs-and-down: "only screen and (max-width : #{$xs-screen})";
$large-and-down: "only screen and (max-width : #{$large-screen})";
$medium-and-down: "only screen and (max-width : #{$medium-screen})";
$xxs-and-down: "only screen and (max-width : #{$xxs-screen})";

$gutter-width: 16px;
$gutter-width-large: 20px;

$navbar-height: 80px;
$navbar-height-mobile: 80px;
$navbar-font-color: #080808;

$card-hr-color: #7b7b7c;
$hero-circle-width: 440px;
$hero-circle-sm-width: 280px;
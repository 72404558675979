#employer-job-applicants-list, #employer-applicants-list {
  td {
    font-family: "nexa-light";
    font-weight: 500;

    &:last-child {
      a:not(:first-child) {
        padding-left: 16px;
      }
    }
  }
}

#view-other-job-applications-modal {
  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    font-size: 16px;
    font-family: "nexa-light";

    li {
      span:first-of-type {
        font-family: "nexa-bold";
      }
    }
  }
}

#add-job-application-comment-modal {
  input[type=text] {
    background-color: #fafafa;
    padding: 6px 0;
    font-weight: bold;
    height: auto;
    font-family: opensans-bold, sans-serif;
  }

  ul {
    font-family: "nexa-light";
    padding: 0;
    margin: 0 10px;
    list-style-type: none;
    font-size: 16px;

    li {
      span {
        display: block;
        color: #c2c0c2;
      }

      blockquote {
        margin-top: 5px;
        border: none;
        padding-left: 0;
      }
    }
  }

  span.btn {
    padding: 0 8px;
  }
}